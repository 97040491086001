import React, { useState, useEffect } from 'react';

import Product from './product';

import Macarons from '../../assets/images/macarons.jpg';
import Gomitas from '../../assets/images/gomitas.jpg';
import Calugas from '../../assets/images/calugas.jpg';
import Cajas from '../../assets/images/cajas.jpg';
import Turrones from '../../assets/images/turrones.jpg';
import Masticables from '../../assets/images/masticables.jpg';
import Mantecados from '../../assets/images/mantecados.jpg';
import Chocolatinas from '../../assets/images/chocolatinas.jpg';
import Marshmallows from '../../assets/images/marshmallows.jpg';
import Caramelos from '../../assets/images/caramelos.jpg';
import Otros from '../../assets/images/otros.jpg';



const Header = ({ location }) => {

  const productArray = [
    { image: Macarons, title: 'Macarons', link: 'https://delivery.dulceriafiol.cl' },
    { image: Gomitas, title: 'Gomitas', link: 'https://delivery.dulceriafiol.cl' },
    { image: Calugas, title: 'Calugas', link: 'https://delivery.dulceriafiol.cl' },
    { image: Cajas, title: 'Cajas', link: 'https://delivery.dulceriafiol.cl' },
    { image: Turrones, title: 'Turrones', link: 'https://delivery.dulceriafiol.cl' },
    { image: Masticables, title: 'Masticables', link: 'https://delivery.dulceriafiol.cl' },
    { image: Mantecados, title: 'Mantecados', link: 'https://delivery.dulceriafiol.cl' },
    { image: Chocolatinas, title: 'Chocolatinas', link: 'https://delivery.dulceriafiol.cl' },
    { image: Marshmallows, title: 'Marshmallows', link: 'https://delivery.dulceriafiol.cl' },
    { image: Caramelos, title: 'Caramelos', link: 'https://delivery.dulceriafiol.cl' },
    { image: Otros, title: 'Otros', link: 'https://delivery.dulceriafiol.cl' },
  ]

  return <div id="productos" className="product-grid-container">
    {productArray.map((prodInfo) =>
      <Product
        productImage={prodInfo.image}
        productTitle={prodInfo.title}
        productLink={prodInfo.link}
        key={prodInfo.title}
      />
    )
    }
  </div>;
};

export default Header;