import React, { useState, useEffect } from 'react';
import background from '../../assets/images/bg-main.jpg';

const Header = ({ location }) => {


  return <div className="header-container" id="home" style={{
    backgroundImage: `url("${background}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }}/>;
};

export default Header;