import React, { useState, useEffect } from 'react';
import { browserVersion, isIE, isSafari } from 'react-device-detect';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MainMenu from '../components/menu';
import Header from '../components/header';
import Products from '../components/products';
import Footer from '../components/footer';

const IndexPage = ({ location }) => {
  const [warn, setWarn] = useState('');


  useEffect(() => {
    setTimeout(function () {
      let warning = null;
      if (typeof window !== 'undefined') {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
        {
          warning = <div style={{ color: 'black', fontSize: '20px' }}>Este sitio no se encuentra soportado en este navegador por favor utilice uno diferente.</div>;
        }
        if (isSafari && browserVersion < 11) {
          warning = <div style={{ color: 'black', fontSize: '20px' }}>Este sitio no se encuentra soportado en este navegador por favor actualice su navegador o utilice uno diferente.</div>;
        }
      }
      setWarn(warning);
    }, 2000);
  }, [])


  return <Layout>
    <SEO title="Inicio" />
    <div className="container" style={{}}>
      <MainMenu />
      <Header />
      <Products />
      <Footer />
    </div>
  </Layout>
};

export default IndexPage;