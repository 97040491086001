import React, { useState, useEffect } from 'react';
import Logo from '../../assets/svg/logo-fiol-dulceria.svg';
import Iso from '../../assets/svg/isotipo.svg';
import Cart from '../../assets/svg/carrito.svg';

const MainMenu = ({ location }) => {

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);


  return <div className="menu-container" style={{
    ...offset > 200 ? {
      backgroundColor: 'white',
      height: '120px',
      boxShadow: '0px 0px 20px 1px rgba(0,0,0, 0.5)',
      padding: '20px'
    } : {}
  }}>
    <div
      className="logo-container"
      style={{
        ...offset > 200 ? { width: '70px' } : {}
      }}
    >
      {offset < 200 ? <Logo /> : <Iso />}
    </div>
    <div className="burger-menu">
      <input type="checkbox" />
      <span></span>
      <span></span>
      <span></span>
      <ul className="menu" style={{
        ...offset > 200 ? { margin: '-100px 0 0 -90vw' } : {}
      }}>
        <a href="#home" className="mobile-menu-item menu-home"><li>Home</li></a>
        <a href="#productos" className="mobile-menu-item menu-products"><li>Productos</li></a>
        <a href="#donde-estamos" className="mobile-menu-item menu-find-us"><li>Donde estamos</li></a>
        <a href="#camila" className="mobile-menu-item menu-camila"><li>Camila</li></a>
        <a href="https://delivery.dulceriafiol.cl/pedir" className="mobile-menu-item menu-cart"><li><Cart /> </li></a>
      </ul>
    </div>
    <div className="menu-list-container">
      <div className="menu-item menu-home"><a className="link-style-remove" href="#home">Home</a></div>
      <div className="menu-item menu-products"><a className="link-style-remove" href="#productos">Productos</a></div>
      <div className="menu-item menu-find-us"><a className="link-style-remove" href="#donde-estamos">Donde estamos</a></div>
      <div className="menu-item menu-camila"><a className="link-style-remove" href="#camila">Camila</a></div>
      <div className="menu-item menu-cart"><a className="link-style-remove" href="https://delivery.dulceriafiol.cl/pedir"><Cart /></a></div>
    </div>
  </div>;
};

export default MainMenu;