import React, { useState, useEffect } from 'react';
import CamilaFiol from '../../assets/images/camila-fiol.png';
import Mapa from '../../assets/images/mapa.png';
import FooterImg from '../../assets/images/footer-img.png'
import WebPay from '../../assets/images/webpay.png'

const Footer = ({ location }) => {


  return <div className="footer-contanier" id="camila">
    <div className="camila-fiol-section">
      <div className="camila-fiol-text">
        <div className="camila-fiol-name">Camila Fiol</div>
        <div className="camila-fiol-description">
          CONVENCIDA QUE LA INVESTIGACIÓN Y EL DESARROLLO SON LA BASE
          PARA CREAR NUEVAS EXPERIENCIAS A TRAVÉS DEL PALADAR.CAMILA FIOL STEPHENS ESTA DECIDIDA A SEGUIR
          CREANDO CONFITES CON SABORES ÚNICOS, QUE NOS CONECTAN CON
          NUESTRO PAIS A TRÁVES DE SUS INGREDIENTES Y SON UN VIAJE PERMANENTE A LA IMAGINACIÓN.
        </div>
      </div>
      <img className="camila-fiol-image" src={CamilaFiol} />
    </div>
    <div id="donde-estamos" className="location-section">
      <img className="location-image" src={Mapa} />
      <div className="location-text">
        <div className="location-text-title">
          Visitanos!
        </div>
        <div className="location-text-description" id="we-are">
          en el corazón de Barrio italia se encuentra nuestra dulceria,
          nuestra cocina y nuestro punto de encuentro. Te invitamos a
          visitarnos y descubrir toda la variedad de dulces que tenemos
          para ti!
        </div>
        <div className="location-text-calltoaction">
          <a href="https://www.google.com/maps/place/Av.+Condell+1065,+Providencia,+Regi%C3%B3n+Metropolitana/@-33.4447339,-70.6289092,16.95z/data=!4m5!3m4!1s0x9662c57f2781df8d:0x20225bbf917333ef!8m2!3d-33.4446954!4d-70.6266986">Haz click aqui</a>
        </div>
      </div>
    </div>
    <div className="footer-image-container">
      <img className="footer-image" src={FooterImg} />
    </div>
    <div className="transbank-image-container">
      <img className="footer-image" src={WebPay} />
    </div>

  </div>;
};

export default Footer;