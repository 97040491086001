import React, { useState, useEffect } from 'react';

const ProductCard = ({ location, productImage, productTitle, productLink }) => {
  return <div className="product-card-container">
    <img src={productImage} />
    <div className="product-title" >{productTitle}</div>
    <a className="link-style-remove" href={productLink}><button className="order-button-link">Ordenar</button></a>
  </div>;
};

export default ProductCard;